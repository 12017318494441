import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [
  {
    title: "",
    subsubCategories: [
      {
        title:
          "Venta de salas de hemodinámica, instalación y asesoría integral",
        subsubsubCategories: [],
      },
      {
        title: "Mantenimiento preventivo y correctivo",
        subsubsubCategories: [],
      },
      {
        title: "Renta de equipos de alta especialidad",
        subsubsubCategories: [],
      },
      {
        title: "Venta y reacondicionamiento de equipo medico",
        subsubsubCategories: [],
      },
    ],
  },
];

const EquipoMedicoExtra = () => {
  return (
    <Layout>
      <Category
        data={data}
        title="Proyectos integrales/Servicios de mantenimiento"
      />
    </Layout>
  );
};

export default EquipoMedicoExtra;
